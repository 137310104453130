<template>
    <div>
        <el-button type="primary" v-print="'#printInfo'">打 印</el-button>
        <div id="printInfo" class="exhibit">
                <div class="emptyNode">
                </div>
                <div class="infoNode">
                    <div class="flexNode">
                        <div class="showInfoNode">
                            <div class="flexNode">
                                <div class="infodes">日期/DATE：</div>
                                <span>{{ date[0] }}</span>
                            </div>
                            <div class="flexNode">
                                <div class="infodes">景区/SCENERY：</div>
                                <span>{{ showInfo.名称 }}</span>
                            </div>
                            <div class="flexNode">
                                <div class="infodes">票价/PRICE：</div>
                                <span>{{ showInfo.价格 }}元</span>
                            </div>
                            <div class="flexNode">
                                <div class="infodes">数量/NUMBER：</div>
                                <span>{{ showInfo.数量 }}张</span>
                            </div>
                        </div>
                    </div>
                    <div class="showERCodeNode">
                        <QRcode
                        width="90"
                        height="90"
                        :content="showInfo.qrUrl"
                        :load="true"
                        ></QRcode>
                    </div>
                    <div class="remark">
                            <div>
                                <div class="smallInfodes">订单号：{{ showInfo.订单号 }}</div>
                            </div>
                        <span v-if="method == '2'">温馨提示：请使用微信扫描二维码取票，并使用畅游景德镇小程序中的畅游码入园</span>
                        <span v-else>温馨提示：此二维码可直接出示给验票员扫码验票后入园</span>
                    </div>
                </div>
                <div class="subInfoNode">
                    <div>
                        <span class="subInfo">订单号：{{ showInfo.订单号 }}</span>
                    </div>
                    <div>
                        <span class="subInfo">明细：{{ title }}</span>
                    </div>
                    <div>
                        <span class="subInfo">支付金额：{{ showInfo.价格 }}元</span>
                    </div>
                    <div>
                        <span class="subInfo">支付方式：{{ orderType }}</span>
                    </div>
                    <div>
                        <span class="subInfo">预定日期：{{ date[0] }}</span>
                    </div>
                </div>
            </div>
    </div>

</template>

<script>
export default {
  name: "goods",
  data() {
    return {
      showInfo: {},
      date: this.$route.query.date,
      method: this.$route.query.method,
      title: '',
      orderTypeOptions: [],
      orderType: '',
    };
  },
  created() {
    let showInfoStr = this.$route.query.printInfo
    this.showInfo = JSON.parse(showInfoStr)
    console.log(this.date, this.showInfo)
    let nameList = this.showInfo.订单明细.map(o => o.名称 + " * " + o.数量)
    this.title = nameList.join("，")

    this.getOrderTypeOptions()
  },
  methods: {
    getOrderTypeOptions() {
      let id = '2e8ac8b3-e201-4c29-91d6-c09deb7785a9'
      let that = this
      that.$axios({
          url: `/mall/enumDetail?id=${id}&timestemp=${ new Date().getTime() }`,
          method: "GET",
      })
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach(o => {
                if (o.Value == that.showInfo.支付方式) {
                    that.orderType = o.Key
                }
            })
          } else {
            that.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
  },
};
</script>

<style scoped>
.exhibit {
    width: 7.5cm;
    height: 18.9cm;
    padding: 0.3cm;
}
.emptyNode {
    width: 100%;
    height: 9.4cm;
}
.infoNode {
    height: 6cm;
    width: 100%;
}
.showInfoNode {
    width: 100%;
    font-size: 0.2cm;
}
.showInfoNode .infodes {
    width: 3cm;
}
.showInfoNode .smallInfodes {
    font-size: 1mm;
}
.subInfoNode {
    width: 95%;
    height: 3.5cm;
    font-size: 1mm;
    margin: 0.2cm;
}
.showERCodeNode {
    width: 100%;

}

.flexNode {
    display: flex;
}
.remark {
    font-size: 12px;
}
</style>
